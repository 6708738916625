import Header from './header';
import Main from './Main';
import Footer from './footer';


function App() {
	return (
		<div className="App">
			<Header />
			<Main />
			<Footer />


		</div>
	);
}

export default App;
