import MessengerCustomerChat from 'react-messenger-customer-chat'

const Main = () => {
	const smiley = ':)';
	return (
		<main className="main">
			{/* About Section */}
			<section id="about" className="section-about">
				<div className="section-about__title main__title">
					<h1>about</h1>
					<img className="star" alt="Star" />
				</div>
				<p className="section-about__text">
					Hi, I'm Mark Jefferson, an aspiring front-end web developer and UI/UX
					designer with a passion for minimalist designs. I'm excited to
					collaborate with you in the future!
				</p>
				<span className="section-about__bonus-text">
					I also love music, as you can see in my photo above. I can also play
					different kinds of instruments, such as the guitar, keyboard, and
					drums {smiley}
					{/* <img
						src={require('./img/sun.png')}
						alt=""
						style={{ height: '12.5em', width: '12.5em' }}
					/> */}
				</span>

				<img
					src={require('./img/about-img.png')}
					alt="About Img"
					className="section-about__img"
				/>
			</section>

			{/* Design Section */}
			<section id="designs" className="section-design">
				<div className="main__title">
					<h1>design</h1>
					<img className="star" alt="Star" />
				</div>
				<div className="project-designs">
					<div className="design section-design-1">
						<div className="img-container-parent">
							<div className="img-container design-1">
								<a
									href="https://www.figma.com/proto/vf0FjE8qjX0g53P0HNXzGJ/Kape-Tayo?page-id=0%3A1&node-id=1-3&scaling=scale-down"
									target="_blank"
									rel="noreferrer"
									className="view-design"
								>
									VIEW DESIGN
								</a>
								<div className="gradient-hover"></div>
							</div>
						</div>
					</div>

					<div className="design section-design-2">
						<div className="img-container-parent">
							<div className="img-container design-2">
								<a
									href="https://www.figma.com/proto/yVeuIx88ueTMyEBzs9k5zz/Slap-For-Bassist?page-id=0%3A1&node-id=1-2&scaling=scale-down"
									target="_blank"
									rel="noreferrer"
									className="view-design"
								>
									VIEW DESIGN
								</a>
								<div className="gradient-hover"></div>
							</div>
						</div>
					</div>

					<div className="design section-design-3">
						<div className="img-container-parent">
							<div className="img-container design-3">
								<a
									href={require('./img/design-3.png')}
									target="_blank"
									rel="noreferrer"
									className="view-design"
								>
									VIEW DESIGN
								</a>
								<div className="gradient-hover"></div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Message Me */}
			<section id="message" className="message-me">
				<h1>
					How's my <br /> porfolio? <br /> <span>Let me know!</span>
				</h1>
				<a className="btn" href="mailto:markjeffersonhidalgo08@gmail.com">
					message me
					<span></span>
				</a>
			</section>

			<MessengerCustomerChat
				pageId='108745442259228'
				appId='1002890434214240'
			/>
		</main>
	);
};

export default Main;
