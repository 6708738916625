const Footer = () => {
	return (
		<footer className="footer">
			<section className="section-footer">
				<div className="line-star">
					<hr />
					<img className="star" alt="Star" />
				</div>

				<li>
					<a
						href="https://www.facebook.com/markjeffersonhidalgo"
						target="_blank"
						rel="noreferrer"
						className="accounts"
					>
						FACEBOOK
					</a>
				</li>
				<li>
					<a
						href="https://www.instagram.com/mrkjeffersn/"
						target="_blank"
						rel="noreferrer"
						className="accounts"
					>
						INSTAGRAM
					</a>
				</li>
				<li>
					<a
						href="https://www.linkedin.com/in/mark-jefferson-hidalgo-33b396271/"
						target="_blank"
						rel="noreferrer"
						className="accounts"
					>
						LINKEDIN
					</a>
				</li>


			</section>
		</footer>
	);
};

export default Footer;
